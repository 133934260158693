import { List, Map } from "immutable";
import { Service } from "../../shared";
import { ApplicationState } from "../../store";

const getProducts = (state: ApplicationState): Map<string, any> => {
  return state.api.get("PRODUCTS");
};

export const isLoadingProducts = (state: ApplicationState): boolean => {
  const productState = getProducts(state);
  return productState ? productState.get("isLoading") : false;
};

export const getProductsResponse = (
  state: ApplicationState
): Service[] | void => {
  if (!getProducts(state) || !getProducts(state).get("payload")) {
    return;
  }

  return getProducts(state).getIn(["payload", "response", "products"]);
};

export const getCursor = (state: ApplicationState): string | void => {
  if (!getProducts(state) || !getProducts(state).get("payload")) {
    return;
  }

  return getProducts(state).getIn([
    "payload",
    "response",
    "paging",
    "nextCursor",
  ]);
};

export const getProductsAggregate = (
  state: ApplicationState
): List<Service> => {
  if (!getProducts(state) || !getProducts(state).get("paginated")) {
    return List();
  }
  return getProducts(state).get("paginated");
};

export const getHasOnlyVirtualProducts = (state: ApplicationState): boolean => {
  const products: List<Service> = getProductsAggregate(state);
  const isProductVirtual = (product: Service) => !!(product && product.virtual);
  return products.size > 0 ? products.every(isProductVirtual) : false;
};

/* Single Product */

const getProduct = (state: ApplicationState): Map<string, any> => {
  return state.api.get("SINGLE_PRODUCT");
};

export const getSelectedProductHasAddons = (
  state: ApplicationState
): boolean => {
  const selectedProduct: Service | void = getSingleProductResponse(state);

  return Boolean(selectedProduct && !!selectedProduct.numberOfAddOns);
};

export const isLoadingSingleProduct = (state: ApplicationState): boolean => {
  if (!getProduct(state)) {
    return false;
  }

  return getProduct(state).get("isLoading");
};

export const getSingleProductResponse = (state: ApplicationState) => {
  if (!getProduct(state) || !getProduct(state).get("payload")) {
    return;
  }

  return getProduct(state).getIn(["payload", "response"]);
};

/* Addo Ons */

const getAddOns = (state: ApplicationState): Map<string, any> => {
  return state.api.get("ADDONS");
};

export const isLoadingAddOns = (state: ApplicationState): boolean => {
  if (!getAddOns(state)) {
    return true;
  }

  return getProducts(state).get("isLoading");
};

export const getAddOnsResponse = (
  state: ApplicationState
): List<Service> | void => {
  if (!getAddOns(state) || !getAddOns(state).get("payload")) {
    return List();
  }

  return List(getAddOns(state).getIn(["payload", "response", "productAddOns"]));
};

/* Layout Type */

export const getCurrentLayout = (state: ApplicationState): string => {
  return state.products.get("layoutType");
};

/* Add-Ons */
const getAddOnsFromResponse = (
  addOnResponse: List<Service> | void,
  selectedAddOns: Service[]
) => {
  return (
    selectedAddOns &&
    selectedAddOns.reduce((acc: any, curAddOn) => {
      const matchingAddOn =
        addOnResponse &&
        addOnResponse.find((aResponse) => aResponse.id === curAddOn.id);
      return [...acc, { ...matchingAddOn }];
    }, [])
  );
};

const getAddOnsByIds = (
  addOnResponse: List<Service> | void,
  addOnIds: string[]
): Service[] => {
  if (!addOnResponse) {
    return [];
  }
  return addOnIds
    .map((id) => addOnResponse.find((aResponse) => aResponse.id === id))
    .filter(Boolean) as Service[];
};

export const getSelectedAddOns = (
  state: ApplicationState
): Service[] | void => {
  const selectedAddOns = state.products.get("selectedAddOns");

  const reservationSelectedAddOns = state.api.getIn([
    "RESERVATION",
    "payload",
    "response",
    "addOnIds",
  ]);

  const addOnResponse = getAddOnsResponse(state);

  if (!selectedAddOns && !reservationSelectedAddOns) {
    return;
  }

  if (!selectedAddOns && reservationSelectedAddOns) {
    return getAddOnsByIds(addOnResponse, reservationSelectedAddOns);
  }

  return addOnResponse && addOnResponse.size === 0
    ? selectedAddOns
    : getAddOnsFromResponse(addOnResponse, selectedAddOns);
};

export const getSelectedAddOnsIds = (state: ApplicationState): string[] => {
  return (getSelectedAddOns(state) || []).map((addOn: Service) => addOn.id);
};

export const getIsPriceInvolved = (state: ApplicationState): boolean => {
  const product = getSingleProductResponse(state);
  const selectedAddOns = getSelectedAddOns(state) || [];
  const selectedAddOnsTotalPrice = selectedAddOns.reduce((prev, current) => {
    return prev + (Number(current.price) || 0);
  }, 0);

  return Boolean(product?.price) || selectedAddOnsTotalPrice > 0;
};
